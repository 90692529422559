import {createRouter, createWebHashHistory} from "vue-router";
import {createApp} from "vue";
import Layout from "~/pages/website/layout.vue";
import Home from "~/pages/website/home.vue";
import About from "~/pages/website/about.vue";
import Eligibility from "~/pages/website/eligibility.vue";
import Benefits from "~/pages/website/benefits.vue";
import TermsAndConditions from "~/pages/website/terms_and_conditions.vue";

import "@/entrypoints/main.css"
import Registration from "~/pages/website/registration.vue";
import Vueform from "@vueform/vueform";
import vueformConfig from "../../../vueform.config.js";
import Faq from "~/pages/website/faq.vue";

const routes = [
    {path: '/', component: Home},
    {path: '/home', component: Home},
    {path: '/about', component: About},
    {path: '/eligibility', component: Eligibility},
    {path: '/benefits', component: Benefits},
    {path: '/faq', component: Faq},
    {path: '/registration', component: Registration},
    {path: '/terms_and_conditions', component: TermsAndConditions},
]
const router = createRouter({
    history: createWebHashHistory(),
    routes,
})
const app = createApp(Layout)
    .use(router).use(Vueform, vueformConfig)
app.mount('#website')